import React from 'react'
import './about.css';
import ME from '../../assets/about-me.jpg';
import {FaAward} from 'react-icons/fa';
import {FiUsers} from 'react-icons/fi';
import {VscFolderLibrary} from 'react-icons/vsc';
import Swing from 'react-reveal/Swing';
import { Link } from 'react-scroll'; 


const About = () => {
  return (
    <section name='about'>
      <h5>Get To Know</h5>
      <h2>Why Me</h2>

      <div className='container about__container'>
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="me-about" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon'/>
              <h5>Experience</h5>
              <small>5+ years</small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon'/>
              <h5>Clients</h5>
              <small>5+ Worldwide</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon'/>
              <h5>Projects</h5>
              <small>10+ Completed</small>
            </article>
          </div>
          <p>
            I have developed highly complex engineering apps with architecture on different blockchains. I am an expert in Finance/Banking platforms, Defi, Smart contracts (Solidity), Cross-blockchain protocols, Yielding/Farming, Staking protocols, NFT’s, Digital wallet, token creation, Decentralised Applications (DAPPS) and end-to-end frontend/backend integration.
            I can build high-quality web development applications with proven and long track records in working with high profile blockchain systems.
            My greatest passion is using our technical know-how to benefit other people and organizations.
          </p>
            <Swing>
            <Link to="contact" smooth={true} duration={500} spy={true} className='btn btn-primary'>Let's Talk</Link>
            </Swing>
        </div>
      </div>
    </section>
  )
}

export default About;