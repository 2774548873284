import React, { useRef, useState } from 'react'
import './contact.css';
import {MdOutlineEmail} from 'react-icons/md';
import {TbBrandDiscord} from 'react-icons/tb';
import {BsWhatsapp} from 'react-icons/bs';
import {TbBrandTelegram} from 'react-icons/tb';
import emailjs from 'emailjs-com';
import Fade from 'react-reveal/Fade';
import toast, { Toaster } from 'react-hot-toast';



const Contact = () => {
  const [message, setMessage] = useState(''); 
  const form = useRef();


//notification
  const successNotify = () => toast.success("Message sent successfully!");
  const errorNotify = () => toast.error("Message not delivered!");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ktsh39j', 'template_dgjoy0s', form.current, 'nG7LD4RB_jq4mbozR')
      .then((result) => {
          setMessage("success")
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
          setMessage("error")
      });

      e.target.reset();
  };

  return (
    <section name='contact'>
      <Toaster />
      <h5>Have a project/idea in mind ?</h5>
      <h2>Connect with Me</h2>
      <div className='container contact__container'>
        <div className="contact__options">
        <Fade bottom big>
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>engagewithme007@gmail.com</h5>
            <a href="mailto:engagewithme007@gmail.com" target="_blank" rel='noreferrer'>Send a Message</a>
          </article>
          </Fade>
          
          <Fade bottom big>
          <article className="contact__option">
            <TbBrandTelegram className='contact__option-icon' />
            <h4>Telegram</h4>
            <h5>Livingstone</h5>
            <a href="https://t.me/Livingstone_IT_Consultancy"  target="_blank" rel='noreferrer'>Send a Message</a>
          </article>
          </Fade>
         
          <Fade bottom big>
          <article className="contact__option">
            <TbBrandDiscord className='contact__option-icon' />
            <h4>Discord</h4>
            <h5>Livingstone</h5>
            <a href=" https://discord.gg/t9dXeg9U"  target="_blank" rel='noreferrer'>Send a Message</a>
          </article>
          </Fade>
         
          <Fade bottom big>
          <article className="contact__option">
            <BsWhatsapp className='contact__option-icon' />
            <h4>Whatsapp</h4>
            <h5>Livingstone</h5>
            <a href="https://api.whatsapp.com/send?phone=447904805276"  target="_blank" rel='noreferrer'>Send a Message</a>
          </article>
          </Fade>
        </div>
        {/* END OF CONTACT OPTIONS*/}
        
        <form ref={form} onSubmit ={sendEmail}>
        <Fade bottom big>
          <input type="text" name="name" placeholder='Your Full Name' required />
          <input type="email" name="email" placeholder='Your Email' required />
          <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
          <button type='submit' onClick={message === 'success' ? successNotify :  errorNotify} className='btn btn-primary'>Submit message</button>
        </Fade>
        </form>
      </div>
    </section>
  )
}

export default Contact;