import React from 'react'
import './testimonials.css';
import AVTR1 from '../../assets/adetunji.jpg';
import AVTR2 from '../../assets/akeem.jpg';
import AVTR3 from '../../assets/avatar1.jpg';
import AVTR4 from '../../assets/avatar4.jpg';

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';




const data = [
  {
    id: 1,
    avatar: AVTR1,
    name: 'Pauline Chukwudi',
    review: "We've witnessed a 200 percent increase in the amount of online contact forms filled out and returned to us since Livingstone developed our new website. Richard and his team collaborated extensively with us to create a site that satisfied all of our requirements. The end product is an appealing, well-organized, and effective website. We say a big thank you!"
  },
  {
    id: 2,
    avatar: AVTR2,
    name: 'Akeem Isah',
    review: "We were able to significantly improve the functionality of our website while lowering our costs with Livingstone's assistance. Our website is much easier to use, has a lot more features, and is really simple to manage. Our new website has made us extremely delighted!.  Thanks Livingstone team for everything you do for us"
  },
  {
    id: 3,
    avatar: AVTR3,
    name: 'Georgina Adao',
    review: 'At Techflow, Inc. we now have a renovated, exciting platform providing excellent information for our clients.  The training and assistance that Livingstone shared allows us to maintain and make necessary changes with a moments notice.  It has been a successful transition for us and we highly recommend them for their blockchain development prowess, SEO technology and outstanding creativity.'
  },
  {
    id: 4,
    avatar: AVTR4,
    name: 'Ernest Achiever',
    review: 'Awesome, Awesome company!!! Livingstone has been helping us for years. They created our website and have continually updated it and adjusted to our growing and changing company. They have increased our online presence in every area we take on. Recently we had some of our listings hacked. Found it on Saturday and everyone started working on it asap and by Monday morning it was all fixed in time for the workday. You can rest comfortably when Livingstone is looking after you..'
  }
]





const Testimonials = () => {
  return (
    <section id='testimonials'>
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>

      <Swiper className='container testimonials__container'
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
      {data.map(({id, avatar, name, review}) => (
                <SwiperSlide key={id} className="testimonial">
                <div className="client__avatar">
                  <img src={avatar} alt="avatar one" />
                </div>
                <h5 className='client__name'>{name}</h5>
                  <small className='client__review'>{review}</small>
              </SwiperSlide>
      ))}

      </Swiper>
    </section>
  )
}

export default Testimonials;