import React from 'react'
import './footer.css';
import {FaFacebookF} from 'react-icons/fa';
import {FiInstagram} from 'react-icons/fi';
import {IoLogoTwitter} from 'react-icons/io';
import { Link } from 'react-scroll'


const Footer = () => {
  return (
    <footer>
      <Link to="home" className='footer__logo'>LIVINGSTONE</Link>

      <ul className='permalinks'>
        <li><Link to="header" smooth={true} duration={500} spy={true}>Home</Link></li>
        <li><Link to="about"smooth={true} duration={500} spy={true} >About</Link></li>
        <li><Link to="experience" smooth={true} duration={500} spy={true}>Experience</Link></li>
        <li><Link to="services" smooth={true} duration={500} spy={true}>Services</Link></li>
        <li><Link to="portfolio" smooth={true} duration={500} spy={true}>Portfolio</Link></li>
        <li><Link to="testimonials" smooth={true} duration={500} spy={true}>Testimonials</Link></li>
        <li><Link to="contact" smooth={true} duration={500} spy={true}>Contact</Link></li>
      </ul>

      <div className="footer__socials">
      <a href="https://m.facebook.com/profile.php?id=100082081963635" target='_blank' rel='noreferrer'><FaFacebookF /></a>
        <a href="https://instagram.com/livinstone_consultancy?igshid=YmMyMTA2M2Y=" target='_blank' rel='noreferrer'><FiInstagram /></a>
        <a href="https://twitter.com/0xLivingStone/" target='_blank' rel='noreferrer'><IoLogoTwitter /></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; LIVINGSTONE {new Date().getFullYear()}. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer