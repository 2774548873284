import React from 'react'
import './services.css';
import {BiCheck} from 'react-icons/bi';
import Slide from 'react-reveal/Slide';


const Services = () => {
  return (
    <section name='services'>
      <h5>I turn revolutionary ideas into reality by creating business models based on the next-generation decentralized Blockchain network.</h5>
      <h2>Our Services</h2>

      <div className="container services__container">
       
       <Slide left>
       <article className='service'>
          <div className='service__head'>
            <h3>UI/UX Design</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Visual/Interactive Design</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>UI/UX Research.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Cross-platform Experience Design.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Prototyping.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Content Strategy.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>UI/UX Consulting.</p>
            </li>
          </ul>
        </article>
       </Slide>

      <Slide bottom>
      <article className='service'>
          <div className='service__head'>
            <h3>Web Development</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Front-end Design Development.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Back-end Development.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Integration.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Web redesign, relocation, and modernization.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Continuous Support and Evolution.</p>
            </li>
          </ul>
        </article>
      </Slide>

        <Slide right>
        <article className='service'>
          <div className='service__head'>
            <h3>Blockchain Development</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Smart Contracts on Blockchains.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Dapp Development.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>DEFI and DAOs Development.</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Custom token Creation(incl. NFTs).</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Decentralized Exchange Deployment.</p>
            </li>
          </ul>
        </article>
        </Slide>
      </div>
    </section>
  )
}

export default Services;