import React from 'react'
import './portfolio.css';
import IMG1 from '../../assets/stone.png';
import IMG2 from '../../assets/wiizzkid.png';
import IMG3 from '../../assets/electro.png';
import IMG4 from '../../assets/zen-x.png';
import IMG5 from '../../assets/nbc.png';
import IMG6 from '../../assets/flashab.png';
import Reveal from 'react-reveal/Reveal';


const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Crypto currency exchange & investment platform',
    github: 'https://github.com/LivingStone-Blockchain/Exchange',
    demo: 'https://stones-exchange.onrender.com'

  },
  {
    id: 2,
    image: IMG2,
    title: 'Wiizzkid education on the blockchain',
    github: 'https://github.com/LivingStone-Blockchain/Wizkid-Education',
    demo: 'https://wiizzkid.com/'

  },
  {
    id: 3,
    image: IMG3,
    title: 'Online stop for gadgets, and computers',
    github: 'https://github.com/LivingStone-Blockchain/acme-widget',
    demo: 'https://electro-cart.onrender.com/'



  },
  {
    id: 4,
    image: IMG4,
    title: 'Decentralized & transparent platform for small artists',
    github: 'https://github.com/LivingStone-Blockchain/Zen-X',
    demo: 'https://zenx.onrender.com/'

  },
  {
    id: 5,
    image: IMG5,
    title: 'News app served in different categories',
    github: 'https://github.com/LivingStone-Blockchain/NBC',
    demo: 'https://nbc-newz.herokuapp.com'
  },
  {
    id: 6,
    image: IMG6,
    title: 'Flash loan tool for securing assets without collateral',
    github: 'https://github.com/LivingStone-Blockchain/Flash-Loan-UI',
    demo: 'https://flashabs.onrender.com/'

  }
];





const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>Recent works</h5>
      <h2>Portfolio</h2>

      <div className='container portfolio__container'>
        {
          data.map(({ id, image, title, github, demo }) => (
            <Reveal effect="fadeInUp" key={id}>
              <article className='portfolio__item'>
                <div className="portfolio__item-image">
                  <img src={image} alt="portfolio" />
                </div>
                <h3>{title}</h3>
                <div className="portfolio__item-cta">
                  <a href={github} className='btn' target="_blank" rel='noreferrer'>Github</a>
                  <a href={demo} className='btn btn-primary' target="_blank" rel='noreferrer'>Live Demo</a>
                </div>
              </article>
            </Reveal>
          ))
        }
      </div>
    </section>
  )
}

export default Portfolio;
