import React from 'react'
import {BsLinkedin} from 'react-icons/bs';
import {RiInstagramFill} from 'react-icons/ri';
import {FaTwitter} from 'react-icons/fa'


const HeaderSocials = () => {
  return (
    <div className="header__socials">
        <a href="https://www.linkedin.com/in/living-stone-blockchain-consultancy-3a8204237" target="_blank" rel='noreferrer noopener'><BsLinkedin /></a>
        <a href="https://instagram.com/livinstone_consultancy?igshid=YmMyMTA2M2Y=" target="_blank" rel='noreferrer noopener'><RiInstagramFill /></a>
        <a href="https://twitter.com/0xLivingStone/" target="_blank" rel='noreferrer noopener'><FaTwitter /></a>
    </div>
  )
}

export default HeaderSocials;