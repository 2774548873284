import React from 'react'
import './header.css';
import CTA from './CTA';
import HeaderSocials from './HeaderSocials';
import Bounce from 'react-reveal/Bounce';
import Personal from '../../assets/personal.png';
import { Link } from 'react-scroll';
import logo from '../../assets/wiizzkid-logo2.png';

const Header = () => {
  return (
    <header name='header'>
      <div className='container header__container'>
       <Bounce top>
       <img src={logo} alt="livingstone_logo" className="logo"/>
        <h1>Livingstone</h1>
       </Bounce>

        <h5 className='text-light'>Using Blockchain Technology to Activate Business Operations</h5>
        <CTA />
        <HeaderSocials />
        
        <div className="me">
          <img src={Personal} alt="me" />
        </div>

        <Link to="contact" smooth={true} duration={500} spy={true} className='scroll__down'>Scroll down</Link>
      </div>
    </header>
  )
}

export default Header;