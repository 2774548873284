import React from 'react'
import './experience.css';
import Fade from 'react-reveal/Fade';


//import HTML from '../../assets/html.png';
//import CSS from '../../assets/css.png';
import JavaScript from '../../assets/javascript.png';
import ReactImg from '../../assets/react.png';
import Node from '../../assets/node.png';
//import Solidity from '../../assets/solidity.png';
//import Rust from '../../assets/rust.png';
//import Mongo from '../../assets/mongo.png';
import Next from '../../assets/nextjs-icon.png';
//import Consult from '../../assets/consult.png';
//import Blockchain from '../../assets/blockchain.png';//
//import SmartContract from '../../assets/smart-contracts.png';
import Dapp from '../../assets/dapp.png';
//import WebApp from '../../assets/webapp.png';
//import Document from '../../assets/document.png';
import NFT from '../../assets/NFT.png';
import Remix from '../../assets/remix.png';
import Solidity_ from '../../assets/solidity_.png';


const experience = () => {
  return (
    <section name='experience'>
      <h5>Areas of Expertise</h5>
      <h2>Experience</h2>
      
      <div className="container experience__content">
          <div className="experience__cards">

        

            {/*<Fade bottom>
              <article className='experience__card'>
                <img src={SmartContract} alt="sc" className='experience__image'/>
                <h5>Smart Contracts</h5>
              </article>*/}

            <Fade bottom>
            <article className='experience__card'>
              <img src={Dapp} alt="dapp" className='experience__image'/>
              <h5>Dapps</h5>
            </article>
            </Fade>


            <Fade bottom>
            <article className='experience__card'>
              <img src={NFT} alt="nft" className='experience__image' />
              <h5>NFTs</h5>
            </article>
            </Fade>

            
          <Fade bottom>
            <article className='experience__card'>
              <img src={JavaScript} alt="blockchain" className='experience__image'/>
              <h5>JavaScript</h5>
            </article>
          </Fade>

            <Fade bottom>
            <article className='experience__card' id="tweak">
              <img src={Remix} alt="remix" className='experience__image' />
              <h5>Remix</h5>
            </article>
             </Fade>

             <Fade bottom>
            <article className='experience__card'>
              <img src={Node} alt="webapp" className='experience__image' />
              <h5>NodeJS</h5>
            </article>
            </Fade>

            <Fade bottom>
            <article className='experience__card'>
              <img src={Solidity_} alt="solidity" className='experience__image' />
              <h5>Solidity</h5>
            </article>
            </Fade>

            <Fade bottom>
            <article className='experience__card'>
              <img src={ReactImg} alt="react" className='experience__image' />
              <h5>React</h5>
            </article>
            </Fade>

            <Fade bottom>
            <article className='experience__card'>
              <img src={Next} alt="mongo" className='experience__image' style={{filter: 'invert(1)'}}/>
              <h5>MongoDb</h5>
            </article>
            </Fade>

          </div>
        </div>


    </section>
  )
}

export default experience;